.App {
  text-align: center;
}
#main{
  padding-bottom: 5px !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.td-sr {
  /* width: 1%; */
  white-space: nowrap;
}

.h-full {
  height: 100%;
}

.eyeOne::-ms-reveal,
.eyeOne::-ms-clear {
  display: none;
}

/* .modal-header {
  padding: 16px !important;
} */

.modal-content {
  padding: 8px !important;
}
.filterScroll{
max-height: 90%;
overflow-y: auto;
overflow-x: hidden;

}
.scrollBody{
  scrollbar-width: none;
}
.scrollBody::-webkit-scrollbar{
  display: none;
}


.number-input {
  -moz-appearance: textfield;
}
.number-input::-webkit-inner-spin-button {
  display: none;
}
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.executiveSelect i{
  display: none;
}

.recharts-x .xAxis{
  display:none;
}

.rechart-surface{
  left: -30px !important;
  position: relative !important;
}




.multiSelectClass .optionListContainer{
  top:auto;
  bottom: 100%;
}

/* 
.ck-editor__editable {
  height: 500px;
  overflow-y: scroll;
}


.ck-body-wraper{ z-index: 1300 !important;}

.ck-powered-by{
  display: none;
} */





.editorr {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 30px;
}
.rdw-editor-main{
  min-height: 400px !important;
  max-height: 500px !important;
  overflow: auto;

}
.editor-wrapper {
  border: 1px solid #c4cdd5;
}
.message-toolbar {
  border-bottom: 1px solid #c4cdd5;
  margin-bottom: 0px;
  padding: 6px 5px;
}
.message-editor {
  height: 150px;
  font-size: 16px;
  width: 100%;
  padding: 0px 10px;
}

.rdw-option-wrapper {
  min-width: 35px;
  height: 30px;
}
.html-output {
  border: 1px solid silver;
  padding: 20px;
  background-color: #fafafa;
}

.leaflet-tooltip{
  font-size: 12px;
  white-space: normal;
}