.documentMasterFile{
display:flex;
flex-wrap: wrap;
}
/* .documentMasterFile:nth-child(1){
    width:10px;
  } */
.file1{
    flex-basis:19%;
}
.file2{
    flex-basis:81%;
}
  /* .documentMasterFile:nth-child(2){
    width:100px;
  } */