
    .tooltip .tooltip-inner{
        max-width: unset !important;
        width: 100% !important; 
    }
    .tooltip .tooltip-inner .tooltip-wrapper{
        max-width: unset !important;
        width: 266px !important; 
    }
    .tooltip-format{
        color:white;
        text-align: left;
    }
