.dropdown-lisst{
    max-height:200px;
    inset: 0px 0px auto 0px !important;
    overflow-y: scroll;
    scrollbar-width: thin;
}
.dropdown-lisst::-webkit-scrollbar {
    width: 7px;
    border-radius: 95%;

  }
  
  .dropdown-lisst::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }

  .dropdown-lisst::-webkit-scrollbar-track-piece:end {
    margin-bottom: 5px; 
}

.dropdown-lisst::-webkit-scrollbar-track-piece:start {
    margin-top: 5px;
}
