
.map_btn_wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    
}

.map_btn_wrapper button{
    background: #feca45;
    /* border: 1px gray solid; */
    padding: 5px 15px;
    border-radius: 4px;
}