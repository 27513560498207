ul>li>a>span {font-size: 14px}
.pagination {
    display: inline-block;
    float:right;
}

.pagination a {
    color: black;
    float: left;
    padding: 6px 9px;
    text-decoration: none;
}
table tr:last-child { border-bottom:1px solid #e5e5e5; }
