ul>li>a>span {font-size: 14px}
.pagination {
    display: inline-block;
    float:right;
}

.pagination a {
    color: black;
    float: left;
    padding: 6px 9px;
    text-decoration: none;
}
table tr:last-child { border-bottom:1px solid #e5e5e5; }
table {
    border-spacing: 0px;
    table-layout: fixed;
    margin-left:auto;
    margin-right:auto;
}
li.nav-item {
    margin-bottom: 1px !important;
}

th {
    vertical-align: middle!important;
}