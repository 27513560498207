.lr-container {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden;
}
.status-cards {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
}

/* .searchbar-container{
    transition : 0.3s ease all;
} */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.requests-icons {
  cursor: pointer;
}
.status-card-count {
  font-weight: 500 !important;
}
.status-card-title {
  display: block;
  color: #2d374b;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: unset;
  text-transform: unset;
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
  .status-cards {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 576px) {
  .status-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* @media screen and (max-width: 576px) { 
    .status-cards{
        grid-template-columns: repeat(3,1fr);

       }
 } */
