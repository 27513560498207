.react-datepicker{
    width: 230px;
    height: 228px;
    padding-bottom: 8px !important;
   
}
.react-datepicker__month-container{
    width: 100%;
}
.react-datepicker__current-month{
    font-size: 16px;
}
.react-datepicker__day-name{
    font-size: 14px !important;
    margin-right: 10px !important;
    /* width: 2.7rem; */
}

.react-datepicker__day{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px !important;
    margin-right: 10px !important;
    margin-bottom: 7px !important;
    /* width: 2.7rem; */
}
 .react-datepicker-wrapper input{
    width: 100% !important;
    border: 1px solid #bdbdbd;
    text-indent: 8px;
 }

 .react-datepicker-wrapper input:disabled{
    background-color:#f5f5f5 ;
 }

 .react-datepicker__header{
    margin-bottom: 13px !important;
    /* padding-top: 8px !important; */
 }
 .react-datepicker__input-time-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    border: none;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border: none;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{
    border: none;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before{
    border: none;
}


.custCal .react-datepicker-popper .react-datepicker{
    font-size: 24px;
    font-weight: 600;
}
 .custCal .react-datepicker{
    height: 100%;
 }


.react-datepicker__input-time-container .react-datepicker-time__caption {
    /* display: inline-block; */
    font-size: 17px;
}


 @media only screen and (min-width: 768px) {


    .react-datepicker-popper{
        /* width: 100%; */
    }
    
    .react-datepicker{
        
        width: 100% !important;
        height: 100% !important;
        font-size: 1.8rem;
    }
    /* .react-datepicker__input-time-container {
        display: flex;
        justify-content: center;
        align-items: center;
    } */
    
    .react-datepicker__month-container{
        width: 100%;
    }
    .react-datepicker__current-month{
        font-size: 16px;
    }
    .react-datepicker__day-name{
        font-size: 14px !important;
        margin-right: 12px !important;
        margin-left: 12px !important;
    }
    
    .react-datepicker__day{
        font-size: 14px !important;
        margin-right: 12px !important;
        margin-left: 12px !important;
        margin-bottom: 8px !important;
    }
     .react-datepicker-wrapper input{
        width: 100% !important;
     }
     .react-datepicker__header{
        margin-bottom: 13px !important;
     }
    
 }