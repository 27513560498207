.filter-box{
    font-size: 13px;
}
.apply-filter-btn{
    cursor: pointer;
}
/* .filterbox-left{
    font-size: 12px;
} */
.track-switch{
    /* margin-left: 10px; */
    padding-left: 40px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
}
.form-check-input{
    

}

.dropdown{
    font-size: 13px;
}

.modall_body{
    height: 95%;
    overflow-y: scroll;
    overflow-x: hidden;
}
